.notification-error {
    background: linear-gradient(
            90deg,
            #B60011,
            #B60011 15px,
            #ebc9c9 15px,
            #ebc9c9 20px);
    color: #B60011;
}

.notification-error .anticon {
    color: #B60011;
}

.notification-error .ant-notification-notice-message {
    color: #B60011;
    font-weight: bold;
}

.notification-info {
    background: linear-gradient(
            90deg,
            #124e9c,
            #124e9c 15px,
            #1c9cff 15px,
            #1c9cff 20px);
    color: #ffffff;
}

.notification-info .anticon {
    color: #124e9c;
}

.notification-info .ant-notification-notice-message {
    color: #124e9c;
    font-weight: bold;
}

.notification-success {
    background: linear-gradient(
            90deg,
            #00582a,
            #00582a 15px,
            #00A236 15px,
            #00A236 20px);
    color: #ffffff;
}

.notification-success .anticon {
    color: #00582a;
}

.notification-success .ant-notification-notice-message {
    color: #00582a;
    font-weight: bold;
}

.notification-warning {
    background: linear-gradient(
            90deg,
            #9b5332,
            #9b5332 15px,
            #e28f3a 15px,
            #e28f3a 20px);
    color: #ffffff;
}

.notification-warning .anticon {
    color: #9b5332;
}

.notification-warning .ant-notification-notice-message {
    color: #9b5332;
    font-weight: bold;
}

.notification-warning .ant-notification-close-icon,
.notification-info .ant-notification-close-icon,
.notification-success .ant-notification-close-icon,
.notification-error .ant-notification-close-icon {
    color: black;
    font-size: 10px;
    font-weight: bold;
}

.error {
  color: #B60011;
}

@media(max-width: 800px) {
    .ant-modal-confirm-btns {
        width: 100%;
    }

    .ant-modal-confirm-btns button {
        width: 100%;
    }
}

.ant-modal-content {
    width: 90%;
    padding: 1px;
    font-weight: bold;
}

.ant-card-head-title {
    font-size: 1.2rem;
    font-weight: 700;
    color: #4A4A4A;
    margin-bottom: 10px;
}

.ant-descriptions-item-label {
    font-weight: bold;
    color: #4A4A4A;
}

.ant-card-grid {
    background: rgb(249, 249, 249, 0.63);
}

/*.ant-collapse-borderless > .ant-collapse-item {*/
/*    border-bottom: none;*/
/*}*/

/*.ant-collapse > .ant-collapse-item {*/
/*    border-bottom: none;*/
/*    !*background-color: #1c67a5;*!*/
/*    !*box-shadow: 1px 1px 6px 2px #ccc*!*/
/*}*/

/*.ant-collapse {*/
/*    box-shadow: 1px 1px 6px 2px #ccc*/
/*}*/

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 1.0rem;
    font-weight: 900;
    color: #4A4A4A;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #fbfbfb;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    margin-top:24px;
}

.ant-tooltip-inner {
    padding: 5px;

}

.ant-tag-green {
    color: #477132;
    background: #f6ffed;
    border-color: #a6c58e;
}
